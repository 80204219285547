import React from "react";
import { Virus } from "tabler-icons-react";
import Faq from "react-faq-component";
import styled from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";
import Wrapper from "../components/wrapper";
import Banner from "../components/banner";
import Button from "../components/button";

import { Flex, Text, useTheme } from "../styled";

const Avatar = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-size: cover !important;
  z-index: 0;
`;

const itemStyle = `class="row-item"`;
const data = {
  rows: [
    {
      title: "Schwerpunkte",
      content: `<div>
        <h5 style="padding: 10px 0 15px">Ziel der Schulung</h5>
        <p>
        Diese Weiterbildung soll das Wissen der einzelnen Fachgesellschaften bündeln und leitlinienbasiertes Fachwissen, ergänzt durch praktische Erkenntnisse und Fertigkeiten, vermitteln. So verfügt der Teilnehmer nach abgelegter Prüfung über eine hohe Wissens- und Handlungskompetenz in der Wundbehandlung und die Grundvoraussetzungen für eine akademische Ausbildung.

Das etwas andere Konzept, drei aufeinander folgende Theorieblöcke an jedem Theorieblock schließt sich ein zweitägiges Praxisseminar an: Unmittelbarer Transfer von theoretisch erworbenem Wissen in die Praxis.
        </p>
        <h5 style="padding: 10px 0 15px">Ziel der Schulung</h5>
        <p>Wundmanager/in WM® und Berechtigung zur Ausbildung zum zertifizierten Wundmanager/in ZWM®</p>
        <h5 style="padding: 10px 0 15px">Modul 1</h5>
        <div style="width: 100%; display: grid;  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); grid-gap: 10px;">
          <div ${itemStyle}>Anatomie / Physiologie Gefäße, Haut</div>
          <div ${itemStyle}>Wunde Pathophysiologie</div>
          <div ${itemStyle}>Aspekte der Hautpflege</div>
          <div ${itemStyle}>Einführung Wundmanagement</div>
          <div ${itemStyle}>Einführung hygienische Standards</div>
          <div ${itemStyle}>Wundreinigung</div>
          <div ${itemStyle}>Wundtherapeutika von A bis Z</div>
        </div>
        <h5 style="padding: 10px 0 15px">Modul 2</h5>
        <div style="width: 100%; display: grid;  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); grid-gap: 10px;">
          <div ${itemStyle}>Wundheilungsstörung</div>
          <div ${itemStyle}>Dekubitus + Lagerung</div>
          <div ${itemStyle}>Diabetisches Fußsyndrom</div>
          <div ${itemStyle}>Ulcus cruris, Kompressionstherapie</div>
          <div ${itemStyle}>Wundtherapeutika von A bis Z</div>
        </div>
        <h5 style="padding: 10px 0 15px">Modul 3</h5>
        <div style="width: 100%; display: grid;  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); grid-gap: 10px;">
          <div ${itemStyle}>Ernährung</div>
          <div ${itemStyle}>Wundinfektion</div>
          <div ${itemStyle}>Antiseptik</div>
          <div ${itemStyle}>Wunddokumentation, Fotografie</div>
          <div ${itemStyle}>Rechtliche Aspekte</div>
          <div ${itemStyle}>Wundtherapeutika von A bis Z</div>
        </div>
      </div>`,
    },
    {
      title: "Symposien",
      content: `in kürze`,
    },
    {
      title: "Termine",
      content: `Aktuell keine`,
    },
    {
      title: "Praxismodule",
      content: `in kürze`,
    },
  ],
};

const Schulungen = React.memo(() => {
  const [rows, setRowsOption] = React.useState(null);

  React.useEffect(() => {
    rows && rows[0].expand();
  }, [rows]);

  const theme = useTheme();
  const styles = {
    bgColor: "transparent",
    titleTextColor: theme.colors.secondary,
    rowTitleColor: theme.colors.secondary,
    rowContentColor: theme.colors.text,
    arrowColor: theme.colors.primary,
    rowContentPaddingTop: "30px",
    rowContentPaddingLeft: "30px",
    rowContentPaddingRight: "30px",
    rowContentPaddingBottom: "50px",
  };
  return (
    <Wrapper p="5rem 0">
      <Flex w="100%">
        <Faq data={data} styles={styles} getRowOptions={setRowsOption} />
      </Flex>
    </Wrapper>
  );
});

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Schulungen" keywords={[`gatsby`, `application`, `react`]} />
      <Hero
        image={require("../images/shutterstock_714357475.jpg")}
        title="Schulung in Theorie und Praxis"
        desc="Seit 2006 veranstalten wir mit der Akademie-ZWM® Wundmanagement Schulungen nach dem Curriculum der Akademie-ZWM® (Basiskurs WM®/ZWM®) in Deutschland. Darüber hinaus führt das WKM-Team Inhouseschulungen und Instruktionen am Patienten im WKM Zentrum zu den Themen Wundmanagement, Kompression, Haut, Dokumentation und Expertenstandard durch."
      />
      <Wrapper pt="3rem">
        <Banner
          icon={<Virus size={58} />}
          title="Wichtiger Hinweis zu COVID-19"
          message="Aufgrund der aktuellen Situation finden bis auf weiteres keine Schulungen und Symposien bei WKM Rösner statt."
        />
      </Wrapper>
      <Schulungen />
      <Wrapper py="3rem">
        <Flex w="100%" bg="secondary" p="3rem" flexCenter>
          <Text font="h4" mb={30} color="#FFF" textAlign="center">
            Weitere Informationen zu
            <br /> unserem Schulungsangebot erteilt
          </Text>
          <Avatar
            style={{
              background: `url(${require("../images/roenser.png")}) no-repeat center center`,
            }}
          />
          <Text font="h5" textAlign="center" mt={10} color="#FFF">
            Friederike Rösner
          </Text>
          <Text mt={5} textAlign="center" color="#FFF">
            Inhaberin WKM
          </Text>

          <Button to="/kontakt" mt={25}>
            E-Mail senden
          </Button>
        </Flex>
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;
